<!--
* 创建人：罗兴
* 日 期：
* 描 述：晚点名
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">晚点名</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button
        type="primary"
        size="mini"
        @click="exportToExcel"
        v-if="hasPerms('sy_export')"
        >到出Excel</el-button
      >
      <el-button type="primary" size="mini" @click="search">查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            v-html="'日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期'"
            class="sm_search_title"
          ></div>
          <el-date-picker
            v-model="RQ"
            size="mini"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
        show-summary
      >
        <el-table-column prop="BMMC" label="学院" width="400">
        </el-table-column>
        <el-table-column prop="YDRS" label="应到人数" sortable>
        </el-table-column>
        <el-table-column prop="YBRS" label="已报人数" sortable>
        </el-table-column>
        <el-table-column prop="SDRS" label="实到人数" sortable>
        </el-table-column>
        <el-table-column prop="WDRS" label="未到人数" sortable>
        </el-table-column>
        <el-table-column prop="QJRS" label="请假人数" sortable>
        </el-table-column>
        <el-table-column prop="WQJRS" label="未请假人数" sortable>
        </el-table-column>
        <el-table-column prop="SXRS" label="实习人数" sortable>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getListDataByRQH, exportListExcel } from '../../api/wdm'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      dialogFormVisible: false,
      keyValue: '',
      RQ: new Date(),
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      buttonauth: {},
    }
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getListDataByRQH({
        rq: this.RQ
          ? moment(this.RQ).format('YYYY-MM-DD')
          : moment(new Date()).format('YYYY-MM-DD'),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.dataPageList = res.data
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    exportToExcel() {
      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportListExcel({
        queryJson: JSON.stringify({
          RQ: this.RQ
            ? moment(this.RQ).format('YYYY-MM-DD')
            : moment(new Date()).format('YYYY-MM-DD'),
        }),
        fileName:
          '成都艺术职业大学学生晚点名情况（' + this.RQ
            ? moment(this.RQ).format('YYYY-MM-DD')
            : moment(new Date()).format('YYYY-MM-DD') + '）',
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$refs.formRef.resetFields()
    },
    bmchange() {},
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
