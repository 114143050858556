import {
  get, excel
} from '../utils/http';

// 获取查询列表
export function getCXPageList(data) {
  return get('wdm/SY_WDMSJ/GetPageList/', data);
}

// 导出信息
export function exportExcel(data) {
  return excel('wdm/SY_WDMSJ/ExportExcel/', data);
}

// 获取查询列表
export function getListDataByRQH(data) {
  return get('wdm/SY_WDMSJ/GetListDataByRQH/', data);
}

// 导出信息
export function exportListExcel(data) {
  return excel('wdm/SY_WDMSJ/ExportListExcel/', data);
}
